<template>
	<div class="info-window">
		<div class="head">
			<div class="info-tab" :class="{active: tab == $options.tabs.rules}" @click="tab = $options.tabs.rules">{{$t('rules')}}</div>
			<div class="info-tab" :class="{active: tab == $options.tabs.play}" @click="tab = $options.tabs.play">{{$t('how_to_play')}}</div>
			<img class="logo" src="~@/assets/logo.png">
			<div class="icon close" @click="$emit('close')"></div>
		</div>
		<div class="info-tab-content rules" :class="{active: tab == $options.tabs.rules}">
			<h3>{{$t('rules_title')}}</h3>
			<h4>{{$t('rules_subtitle1')}}</h4>
			<p>{{$t('rules_text1')}}</p>
			<h4>{{$t('rules_subtitle2')}}</h4>
			<p>{{$t('rules_text2')}}</p>
			<h4>{{$t('rules_subtitle3')}}</h4>
			<p>{{$t('rules_text3')}}</p>
			<h4>{{$t('rules_subtitle4')}}</h4>
			<p>{{$t('rules_text4')}}</p>
			<i>{{$t('rules_disclaimer')}}</i>
		</div>
		<div class="info-tab-content" :class="{active: tab == $options.tabs.play}">
			<div class="step step1">
				<div class="num">1</div>
				<p>{{$t('info_step_1')}}</p>
				<div class="betting">
					<div class="widget">
						<div class="auto">
							<div class="label">auto</div>
							<div class="switcher"></div>
						</div>
						<div class="title">{{$t('bet')}}</div>
						<div class="input">
							<input value="1.00" readonly />
							<div class="valute">{{currency}}</div>
							<div class="input-btn plus"></div>
							<div class="input-btn minus"></div>
						</div>
					</div>
					<div class="widget widget2">
						<div class="auto">
							<div class="label">auto</div>
							<div class="switcher on"></div>
						</div>
						<div class="title">{{$t('take')}}</div>
						<div class="input">
							<input value="1.00" readonly />
							<div class="valute">X</div>
							<div class="input-btn plus"></div>
							<div class="input-btn minus"></div>
						</div>
					</div>
				</div>
				<div class="bet-btn">
					<h4>{{$t('make_bet')}}</h4>
					<span>{{$t('this_round')}}</span>
				</div>
			</div>
			<div class="step step2">
				<div class="num">2</div>
				<p>{{$t('info_step_2')}}</p>
				<img src="~@/assets/manual5.png"/>
			</div>
			<div class="step step3">
				<div class="num">3</div>
				<p>{{$t('info_step_3')}}</p>
				<div class="betting">
					<div class="widget">
						<div class="auto">
							<div class="label">auto</div>
							<div class="switcher"></div>
						</div>
						<div class="title">{{$t('bet')}}</div>
						<div class="input">
							<input value="1.00" readonly />
							<div class="valute">{{currency}}</div>
							<div class="input-btn plus"></div>
							<div class="input-btn minus"></div>
						</div>
					</div>
					<div class="widget widget2">
						<div class="auto">
							<div class="label">auto</div>
							<div class="switcher on"></div>
						</div>
						<div class="title">{{$t('take')}}</div>
						<div class="input">
							<input value="1.00" readonly />
							<div class="valute">X</div>
							<div class="input-btn plus"></div>
							<div class="input-btn minus"></div>
						</div>
					</div>
				</div>
				<div class="bet-btn">
					<h4>{{$t('take_bet')}}</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const tabs = {
	rules: 1,
	play: 2
};

export default {
	name: 'History',
	tabs: tabs,
	data(){
		return {tab: tabs.play };
	},
	computed: {
		currency(){
			const symbols = {usd: '$', eur: '€', uah: '₴', credit: 'CR', gel: '₾'};
			let currency = this.$g.currency.toLowerCase();
			return symbols[currency] ? symbols[currency] : currency;
		}
	}
}
</script>

<style>
	.info-window{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 200;
		overflow: auto;
		background: #1d2c34;
	}
	.info-window .head{
		height: 100px;
	}
	.info-window .logo{
		margin-left: auto;
	}
	.info-window .head .close{
		height: 40px;
		width: 40px;
		margin-left: 34px;
		background-size: 34px;
	}
	.info-tab{
		height: 100%;
		padding: 0 20px;
		color: #00df09;
		line-height: 90px;
		font-size: 24px;
		cursor: pointer;
	}
	.info-tab.active{
		border-bottom: 2px solid #00df09;
	}
	.info-tab-content{
		display: none;
	}
	.info-tab-content.active{
		display: block;
	}
	.step{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 67px 36px;
		color: #80a1d0;
		font-size: 24px;
	}
	.step .num{
		flex-shrink: 0;
		width: 86px;
		height: 86px;
		margin-right: 5%;
		border: 2px solid #33465c;
		border-radius: 9px;
		color: #33465c;
		line-height: 83px;
		font-size: 96px;
		text-align: center;
	}
	.step p{
		margin: 0;
		flex-grow: 1;
	}
	.step img{
		max-width: 25%;
		margin-left: 24px;
	}
	.step .betting{
		padding-right: 0;
	}
	.step .widget2{
		display: none;
		margin-bottom: 0;
	}
	.step .widget .title{
		text-align: center;
	}
	.step .widget .valute{
		margin-top: 0;
	}
	.step .bet-btn{
		position: static;
		/*min-width: 150px;*/
		margin-right: 0;
		height: 55px;
		color: #000;
	}
	.rules{
		padding: 36px;
		color: #80a1d0;
		font-size: 16px;
		text-transform: uppercase;
	}
	.rules h3{
		margin-bottom: 20px;
		color: #00df09;
		font-size: 26px;
	}
	.rules h4{
		margin-bottom: 15px;
		color: #fff500;
		font-size: 20px;
	}
	@media (max-width: 1200px) {
		.step{
			padding: 5.58% 3%;
		}
	}
	@media (max-width: 800px) {
		.step p{
			font-size: 18px;
		}
		.step .num{
			width: 60px;
			height: 60px;
			line-height: 55px;
			font-size: 50px;
		}
		.step .betting{
			margin: 0 -40px;
			transform: scale(0.7);
		}
		.step .bet-btn{
			height: 35px;
			padding-bottom: 0;
		}
	}
	@media (max-width: 650px) {
		.info-window .head{
			flex-wrap: wrap;
			padding: 0;
		}
		.info-window .logo{
			max-width: 50%;
			height: 30px;
			margin-left: 0;
			margin-right: 40%;
		}
		.info-window .head .close{
			height: 30px;
			width: 30px;
			margin-left: auto;
			background-size: 26px;
		}
		.info-tab{
			order: 6;
			width: 50%;
			height: 39px;
			background: #15252c;
			line-height: 39px;
			font-size: 18px;
			text-align: center;
		}
	}
	@media (max-width: 500px) {
		.info-window .logo{
			margin-right: 30%;
		}
		.step{
			flex-wrap: wrap;
		}
		.step p{
			width: calc(100% - 100px);
			margin-bottom: 15px;
		}
		.step1 .num,
		.step3 .num{
			margin-bottom: 15px;
			width: 30px;
			height: 30px;
			border-radius: 5px;
			line-height: 27px;
			font-size: 22px;
		}
		.step img{
			width: 40%;
			max-width: none;
			margin: 0;
		}
		.step .betting{
			margin: 0;
			transform: none;
		}
		.step .widget{
			padding: 2px;
		}
		.step .widget2{
			display: flex;
		}
		.step .bet-btn{
			height: 70px;
		}
		.step .input input,
		.step .input .valute{
			font-size: 14px;
		}
		.step .input input{
			height: 27px;
		}
		.step2 p{
			width: 100%;
			order: 1;
			text-align: center;
		}
		.step2 .num{
			order: 2;
			width: 60px;
			height: 60px;
			line-height: 56px;
			font-size: 52px;
		}
		.step2 img{
			order: 3;
		}
	}
	@media (max-width: 300px) {
		.info-window .logo{
			max-width: none;
			margin-right: 0;
		}
		.info-tab{
			padding: 0;
			font-size: 14px;
			text-align: center;
		}
		.step1 .num,
		.step3 .num{
			margin-bottom: 15px;
			width: 25px;
			height: 25px;
			line-height: 21px;
			font-size: 20px;
		}
		.step p {
			font-size: 14px;
		}
		.step .title{
			width: 30px;
			font-size: 8px;
		}
		.step .input input,
		.step .input .valute{
			font-size: 12px;
		}
		.step .input input{
			width: 24px;
		}
		.step .input .valute{
			width: 18px;
		}
	}
</style>