<template>
	<div class="betting" v-if="$g.game">
		<div class="widget">
			<div class="auto">
				<div class="label" :class="{active: bet.auto}">auto</div>
				<div class="switcher" :class="{on: bet.auto}" @click="switchAutoBet"></div>
			</div>
			<div class="title">{{$t('bet')}}</div>
			<div class="input">
				<input :value="bet.amount" :min="$options.min" :max="$options.max" :readonly="$g.isMobileSize"
					@input="e => setAmnt(e.target.value, bet)" @focusin="focusIn(bet)" @focusout="focusOut" ref="betInput"/>
				<div class="valute">{{currency}}</div>
				<div class="input-btn plus" @click="incrAmnt(bet)"></div>
				<div class="input-btn minus" @click="decrAmnt(bet)"></div>
			</div>
		</div>
		<div class="bet-btn" :class="{disabled: isDisabled}" @click="submit">
			<h4>{{btnTitle}}</h4>
			<span>{{btnLabel}}</span>
		</div>
		<div class="widget">
			<div class="auto">
				<div class="label" :class="{active: take.auto}">auto</div>
				<div class="switcher" :class="{on: take.auto}" @click="toggleTake()"></div>
			</div>
			<div class="title">{{$t('take')}}</div>
			<div class="input">
				<input :value="take.amount" :min="$options.min" :max="$options.max" :readonly="$g.isMobileSize"
					@input="e => setAmnt(e.target.value, take)" @focusin="focusIn(take)" @focusout="focusOut" ref="takeInput" />
				<div class="valute">X</div>
				<div class="input-btn plus" @click="incrAmnt(take)"></div>
				<div class="input-btn minus" @click="decrAmnt(take)"></div>
			</div>
		</div>
		<div class="calculator" v-if="focused" @mousedown.prevent>
			<div class="num" @click="type(1)">1</div>
			<div class="num" @click="type(2)">2</div>
			<div class="num" @click="type(3)">3</div>
			<div class="num" @click="type(4)">4</div>
			<div class="num" @click="type(5)">5</div>
			<div class="num" @click="type(6)">6</div>
			<div class="num" @click="type(7)">7</div>
			<div class="num" @click="type(8)">8</div>
			<div class="num" @click="type(9)">9</div>
			<div class="num" @click="type('.')">.</div>
			<div class="num" @click="type(0)">0</div>
			<div class="num bksp" @click="bksp()"></div>
			<div class="btn" @click="focusOut()">{{$t('cancel')}}</div>
			<div class="btn" @click="focusOut()">{{$t('ok')}}</div>
		</div>
	</div>
</template>

<script>
const GENERAL_ERROR_CODE = 100;
const BALANCE_ERROR_CODE = 101;
const SUM_LOW_CODE = 102;
const SUM_HIGH_CODE = 103;
const INVALID_CURRENCY_CODE = 104;

const AUTO_JUMP_ADDED = 1;
const AUTO_JUMP_UPDATED = 2;
const AUTO_JUMP_REMOVED = 3;
const AUTO_JUMP_NOT_FOUND = 4;
const AUTO_JUMP_GAME_STARTED = 5;
const AUTO_JUMP_ERROR = 6;

export default {
	name: 'Betting',
	props: ['index'],
	data(){
		return {
			bet: {
				auto: false,
				amount: 0.1
			},
			take: {
				auto: false,
				amount: 10
			},
			focused: null,
			processing: false,
			id: null
		};
	},
	min: 0.1,
	max: 300,
	takeMin: 1.01,
	computed: {
		btnTitle(){
			if(this.processing) return this.$t('registering');
			return this.id ? this.$t('take_bet') : this.$t('make_bet');
		},
		btnLabel(){
			if(this.processing || this.id) return '';
			return (this.$g.game.State == this.$api.states.start) ? this.$t('this_round') : this.$t('next_round');
		},
		isDisabled(){
			let betting = this.$g.game.State == this.$api.states.start;
			if(this.processing) return true;	//disable while request is going
			if(this.id && betting) return true;	//disable btn if made bet but cannot withdraw yet
			if(!this.id && !betting) return true;	//disable if betting phase is over
			return false;
		},
		currency(){
			const symbols = {usd: '$', eur: '€', uah: '₴', credit: 'CR', gel: '₾'};
			let currency = this.$g.currency.toLowerCase();
			return symbols[currency] ? symbols[currency] : currency;
		}
	},
	watch: {
		/*bet: {
			deep: true,
			handler(){ this.saveSettings(); }
		},
		take: {
			deep: true,
			handler(){ this.saveSettings(); }
		}*/
	},
	created(){
		//this.loadSettings();
		this.$api.getGame().then(response => {
			if(!response.data || !response.data[0] || !response.data[0].id) return;
			let gameId = response.data[0].id;
			this.$api.getOwnBets().then(response => {
				if(!response.data || !response.data) return;
				let first = true;
				response.data.every(bet => {
					if(bet.Tirazh != gameId) return true;
					if(this.index && first){
						first = false;
						return true;
					}
					this.id = bet.id;
					return false;
				});
				return response;
			});
			return response;
		});
		this.$api.socket.on('game_state', data => {
			if(data.State == this.$api.states.start) setTimeout(this.registerAutoBet, 1000);
			if(data.State == this.$api.states.end) {
				this.oldId = this.id;
				this.id = null;
				this.processing = false;
			}
		});
		this.$api.socket.on('Jumped', data => {
			if(data.id != this.id && data.id != this.oldId) return;
			this.warningMessage(this.$t('bet_taken', [data.Bet, (data.Bet * data.Amount).toFixed(2)]));
			this.id = null;
			this.processing = false;
			this.playWithdrawSound();
			if(this.$g.demoBalance) this.$g.balance += parseFloat((data.Bet * data.Amount).toFixed(2));
		});
		this.$api.socket.on('jump_error', data => {
			if(data.id != this.id) return;
			this.errorMessage(this.$t('jump_error', [data.message]));
			this.id = null;
			this.processing = false;
		});
		this.$api.socket.on('auto_jump_state', data => {
			if(data.betId != this.id) return;
			switch(data.state){
				case AUTO_JUMP_ADDED:
					//this.successMessage('auto jump registered');
				break;
				case AUTO_JUMP_UPDATED:
					//this.successMessage('auto jump updated');
				break;
				case AUTO_JUMP_REMOVED:
					//this.successMessage('auto jump removed');
				break;
				case AUTO_JUMP_NOT_FOUND:
					//this.warningMessage('auto jump should have been removed, but is not registered');
				break;
				case AUTO_JUMP_GAME_STARTED:
					this.errorMessage('auto jump cannot be changed after game start');
				break;
				case AUTO_JUMP_ERROR:
					this.errorMessage('auto jump error');
				break;
				default:
					this.errorMessage('unknown auto jump state');
			}
		});
	},
	methods: {
		switchAutoBet(){
			this.bet.auto = !this.bet.auto;
			if(this.bet.auto && !this.id) this.registerAutoBet();
		},
		toggleTake(){
			if(!this.take.auto && this.$g.game.State == this.$api.states.flight) return;	//do not enable auto take when already in flight
			this.take.auto = !this.take.auto;
			if(this.id) this.autoJump();
		},
		setAmnt(value, type, timeout){
			if(!timeout){	//event is triggered by input, delay it a bit
				if(this.validationTimeout) clearTimeout(this.validationTimeout);
				//short delay to give some time for user to stop typing
				this.validationTimeout = setTimeout(() => this.setAmnt(value, type, true), 500);
				return;
			}
			if(value === '') return;
			//remove invalid characters
			let amount = parseFloat(this.roundDecimals(value.replace(/[^0-9\.]+/g, '')));
			if(type == this.bet && !this.validateBet(amount)) return;
			if(type == this.take && !this.validateTake(amount)) return;
			type.amount = amount.toString();
			if(type == this.take && this.id && this.take.auto){
				if(this.autoJumpTimeout) clearTimeout(this.autoJumpTimeout);
				this.autoJumpTimeout = setTimeout(() => this.autoJump(), 500);
			}
		},
		validateBet(value){
			if(value > this.$options.max){
				this.bet.amount = this.$options.max;
				this.errorMessage(this.$t('bet_max_err', [this.$options.max]));
				return false;
			}
			if(value < this.$options.min){
				this.bet.amount = this.$options.min;
				this.errorMessage(this.$t('bet_min_err', [this.$options.min]));
				return false;
			}
			return true;
		},
		validateTake(value){
			if(value < this.$options.takeMin){
				this.take.amount = this.$options.takeMin;
				this.errorMessage(this.$t('take_min_err', [this.$options.takeMin]));
				return false;
			}
			return true;
		},
		incrAmnt(type){
			if(type == this.take && this.id && this.$g.game.State == this.$api.states.flight) return;	//not allow auto jump change in flight
			let amount = parseFloat(type.amount);
			amount += (amount < 1) ? 0.1 : 1;
			if(type == this.bet && !this.validateBet(amount)) return;
			if(type == this.take && !this.validateTake(amount)) return;
			type.amount = parseFloat(this.roundDecimals(amount));
			if(type == this.take && this.id && this.take.auto) this.autoJump();
		},
		decrAmnt(type){
			if(type == this.take && this.id && this.$g.game.State == this.$api.states.flight) return;	//not allow auto jump change in flight
			let amount = parseFloat(type.amount);
			amount -= (amount > 1) ? 1 : 0.1;
			if(type == this.bet && !this.validateBet(amount)) return;
			if(type == this.take && !this.validateTake(amount)) return;
			type.amount = parseFloat(this.roundDecimals(amount));
			if(type == this.take && this.id && this.take.auto) this.autoJump();
		},
		submit(){
			if(this.processing){
				this.errorMessage(this.$t('bet_register_err'));
				return;
			}
			if(this.id) this.jump();
			else this.registerBet();
		},
		registerBet(){
			if(this.isDisabled) return;
			if(this.bet.amount > this.$options.max){
				this.errorMessage(this.$t('bet_max_err', [this.$options.max]));
				return
			}
			if(this.bet.amount < this.$options.min){
				this.errorMessage(this.$t('bet_min_err', [this.$options.min]));
				return
			}
			if(this.bet.amount > this.$g.balance){
				this.errorMessage(this.$t('err_balance'));
				return
			}
			let amount = this.bet.amount;	//to avoid invalid amount if it changes during request
			this.$api.bet(amount).then(response => {
				this.processing = false;
				if(!response.data.id) throw new Error('no bet id in response');
				this.id = response.data.id;
				if(this.$g.demoBalance) this.$g.balance -= amount;
				else this.$g.balance = response.data.balance;
				this.$g.ownBets.unshift({
					Date: Date.now(),
					Amount: amount,
					Result: null,
					Bet: null
				});
				this.successMessage(this.$t('bet_accepted'));
				if(this.take.auto) this.autoJump();
				this.$api.getJackpot().then(response => this.$g.jackpot = response.data.Amount);
				this.playBetSound();
			}).catch(error => {
				this.processing = false;
				this.betErrorMessage(error.response ? error.response.data : null);
			});
			this.processing = true;
		},
		registerAutoBet(){
			if(!this.bet.auto || this.isDisabled) return;	//bet is already registered
			if(this.bet.amount < this.$options.min || this.bet.amount > this.$options.max) return;
			if(this.bet.amount > this.$g.balance){
				this.errorMessage(this.$t('err_balance'));
				return
			}
			if(this.$g.demoBalance) this.$g.balance -= this.bet.amount;
			this.$api.bet(this.bet.amount).then(response => {
				if(!response.data.id) throw new Error('no bet id in response');
				this.id = response.data.id;
				if(!this.$g.demoBalance) this.$g.balance = response.data.balance;
				this.successMessage(this.$t('auto_bet_accepted'));
				if(this.take.auto) this.autoJump();
				this.$api.getJackpot().then(response => this.$g.jackpot = response.data.Amount);
				this.playBetSound();
			}).catch(error => {
				this.betErrorMessage(error.response ? error.response.data : null, true);
			});
		},
		betErrorMessage(error, auto){
			if(error.code == INVALID_CURRENCY_CODE){
				this.errorMessage(this.$t('err_invalid_currency'));
			}else if(error.code == BALANCE_ERROR_CODE){
				this.errorMessage(this.$t('err_server_balance'));
			} else if(error.code == SUM_LOW_CODE){
				if(error.min) this.errorMessage(this.$t('err_server_min_sum', [error.min]));
				else this.errorMessage(this.$t('err_server_min'));
			} else if(error.code == SUM_HIGH_CODE){
				if(error.max) this.errorMessage(this.$t('err_server_max_sum', [error.max]));
				else this.errorMessage(this.$t('err_server_max'));
			} else this.errorMessage(auto ? this.$t('auto_bet_rejected') : this.$t('bet_rejected'));
		},
		jump(coefficient){
			if(this.$g.game.State != this.$api.states.flight) return;
			if(this.processing) return;
			this.processing = true;
			this.$api.socket.emit('Jump', {
				token: this.$api.token,
				coefficient: coefficient ? coefficient : this.$g.coefficient,
				GameID: this.$g.game.id,
				BetID: this.id
			});
		},
		autoJump(){
			this.$api.socket.emit('autoJump', {
				betId: this.id,
				gameId: this.$g.game.id,
				coefficient: parseFloat(this.take.amount),
				auto: this.take.auto
			});
		},
		focusIn(type){
			this.focused = type;
			type.amount = '';
		},
		focusOut(){
			if(!this.focused) return;
			let value = this.focused == this.bet ? this.$refs.betInput.value : this.$refs.takeInput.value;
			if(value === '') value = this.focused == this.bet ? this.$options.min.toString() : this.$options.takeMin.toString();
			this.setAmnt(value, this.focused, true);
			this.focused = null;
		},
		type(num){
			if(!this.focused) return;
			let amount = this.focused.amount.toString();
			if(num == '.' && amount.includes('.')) return;	//trying to enter '.', but input already has decimal delimeter
			if((amount.split('.')[1] || []).length >= 2) return;	//trying to enter more fractions that allowed
			let result = amount + num.toString();
			if(parseFloat(result) > this.$options.max){
				result = this.$options.max;
				this.errorMessage(this.$t('bet_max_err', [this.$options.max]));
			}
			this.focused.amount = result;
		},
		bksp(){
			if(!this.focused) return;
			let value = this.focused.amount.toString();
			this.focused.amount = value.substr(0, value.length -1);
		},
		roundDecimals(value){	//when bet has decimal places decrease/increas sometimes causes rounding errors, fix them by rounding to 1 decimal
			if(typeof value != 'number') value = parseFloat(value);
			if(Number.isNaN(value)) value = 1;
			return value.toFixed(2).toString();
		},
		loadSettings(){
			try{
				let settings = JSON.parse(localStorage.getItem('settings'));
				let index = this.index ? this.index : 0;
				let bet = settings.bet[index];
				let take = settings.take[index];
				this.bet.auto = bet.auto;
				this.bet.amount = bet.amount ? bet.amount : 1;
				this.take.auto = take.auto;
				this.take.amount = take.amount ? take.amount : 1;
			}catch(error){
				return;
			}
		},
		saveSettings(){
			let settings;
			let index = this.index ? this.index : 0;
			try{
				settings = JSON.parse(localStorage.getItem('settings'));
				if(!settings.bet) settings.bet = [];
				settings.bet[index] = this.bet;
				if(!settings.take) settings.take = [];
				settings.take[index] = this.take;
			}catch(error){
				settings = {};
			}
			localStorage.setItem('settings', JSON.stringify(settings));
		},
		playBetSound(){
			this.playSound(this.$g.audio.bet);
		},
		playWithdrawSound(){
			//delay sound to allow explosion to complete
			if(this.$g.game.State == this.$api.states.end) return setTimeout(() => this.playWithdrawSound, 1000);

			this.playSound(this.$g.audio.withdraw);
		},
		playSound(sound){
			if(!sound) return;
			if(!sound.paused) sound.pause();
			sound.currentTime = 0;
			sound.play();
		}
	}
}
</script>

<style>
	.betting{
		display: flex;
		align-items: center;
		margin: 4px 6px 4px 3px;
	}
	.betting .widget{
		display: flex;
		align-items: center;
		flex-grow: 1;
		padding: 2px 10px 3px 5px;
		background: #33465c;
		border-radius: 3px;
	}
	.auto{
		font-size: 12px;
		text-align: center;
	}
	.auto .label{
		margin-bottom: 6px;
		color: #fff;
	}
	.auto .label.active{
		color: #fff500;
	}
	.switcher {
		display: inline-block;
		vertical-align: middle;
		width: 38px;
		padding: 2px;
		background: #000;
		border-radius: 32px;
		line-height: 0;
		text-align: left;
		cursor: pointer;
	}
	.switcher.on {
		border-color: #4c84ff;
		text-align: right;
	}
	.switcher:before {
		content: '';
		display: inline-block;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: #33465c;
	}
	.switcher.on:before {
		background: #fff500;
	}
	.widget .title{
		width: 55px;
		margin: auto;
		color: #fff500;
		font-size: 12px;
		text-transform: uppercase;
	}
	.input{
		display: flex;
		align-items: center;
		flex-grow: 0.5;
		padding-right: 11px;
		border-radius: 3px;
		background: #000;
		color: #ff000f;
		font-size: 18px;
		font-weight: 900;
	}
	.input input{
		flex-grow: 0.5;
		width: 63px;
		height: 37px;
		margin-right: 3px;
		border: none;
		background: #000;
		color: #ff000f;
		line-height: 24px;
		font-size: 18px;
		font-weight: 900;
		text-align: right;
        font-family: 'Poppins', sans-serif;
		outline: none;
	}
	.valute{
		width: 25px;
		margin-top: 2px;
		line-height: 24px;
		margin-right: auto;
	}
	.input-btn{
		width: 27px;
		height: 20px;
		cursor: pointer;
	}
	.input-btn.plus{
		background: url("data:image/svg+xml,%3Csvg width='20' height='19' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2143 7.80357H12.1429C11.9456 7.80357 11.7857 7.65165 11.7857 7.46429V1.69643C11.7857 0.759583 10.9862 0 10 0C9.01385 0 8.21429 0.759583 8.21429 1.69643V7.46429C8.21429 7.65165 8.05437 7.80357 7.85714 7.80357H1.78571C0.799561 7.80357 0 8.56315 0 9.5C0 10.4368 0.799561 11.1964 1.78571 11.1964H7.85714C8.05437 11.1964 8.21429 11.3483 8.21429 11.5357V17.3036C8.21429 18.2404 9.01385 19 10 19C10.9862 19 11.7857 18.2404 11.7857 17.3036V11.5357C11.7857 11.3483 11.9456 11.1964 12.1429 11.1964H18.2143C19.2004 11.1964 20 10.4368 20 9.5C20 8.56315 19.2004 7.80357 18.2143 7.80357Z' fill='%23DCF7FD'/%3E%3C/svg%3E%0A") center no-repeat;
	}
	.input-btn.minus{
		background: url("data:image/svg+xml,%3Csvg width='20' height='5' viewBox='0 0 20 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.0645 0.661316H1.93548C0.870968 0.661316 0 1.48874 0 2.50003C0 3.51132 0.870968 4.33874 1.93548 4.33874H18.0645C19.129 4.33874 20 3.51132 20 2.50003C20 1.48874 19.129 0.661316 18.0645 0.661316Z' fill='%23DCF7FD'/%3E%3C/svg%3E%0A") center no-repeat;
	}
	.bet-btn{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 192px;
		height: 55px;
		margin: 0 5px;
		padding-bottom: 7px;
		border-radius: 3px;
		background: #00df09;
		line-height: 17px;
		cursor: pointer;
	}
	.bet-btn h4{
		font-size: 18px;
		font-weight: 400;
	}
	.bet-btn span{
		font-size: 12px;
	}
	.bet-btn.disabled{
		background: gray;
	}
	.calculator{
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin-top: 5px;
		display: none;
		flex-wrap: wrap;
		justify-content: space-between;
		background: #1d2c34;
		color: #fff;
		line-height: 40px;
		font-size: 24px;
		text-align: center;
	}
	.calculator .num{
		flex-basis: 30%;
		flex-grow: 1;
		margin: 0 2.5px 5px;
		background: #000;
		cursor: pointer;
	}
	.calculator .btn{
		flex-basis: 45%;
		flex-grow: 1;
		margin: 0 2.5px;
		background: #000;
		cursor: pointer;
	}
	.calculator .bksp{
		background: #000 url('~@/assets/bksp.svg') center no-repeat;
		background-size: auto 80%;
	}

	@media (max-width: 1000px) {
		.betting{
			position: relative;
			display: block;
			padding-right: 205px;
		}
		.widget{
			margin-bottom: 4px;
		}
		.bet-btn{
			position: absolute;
			top: 0;
			right: 0;
			height: 94px;
		}
	}

	@media (max-width: 680px) {
		.calculator{
			display: flex;
		}
	}

	@media (max-width: 500px) {
		.betting{
			padding-right: 43%;
			margin: 4px 0px;
		}
		.auto{
			font-size: 10px;
		}
		.auto .label {
			margin-bottom: 2px;
		}
		.widget .title{
			width: 35px;
			font-size: 9px;
		}
		.switcher{
			width: 22px;
		}
		.switcher::before{
			width: 9px;
			height: 9px;
		}
		.input{
			padding: 0 5px;
			font-size: 16px;
		}
		.input input{
			order: 1;
			width: 30px;
			padding: 0;
			font-size: 16px;
		}
		.valute{
			order: 2;
			margin-top: 1px;
			margin-right: 0;
		}
		.input-btn.plus, .input-btn.minus{
			width: 15px;
			height: 15px;
			background-size: 80%;
		}
		.minus{
			order: 3;
		}
		.bet-btn{
			height: 88px;
			width: 40%;
			text-align: center;
		}
	}
</style>