import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from './translations.js';
import App from './App.vue';
import globals from './global.js';
import notifications from './components/notifications.js';
import API from './api.js';

Vue.config.productionTip = false;

Vue.use(VueI18n);

var params = new URLSearchParams(window.location.search);
var locale = params.get('lang');
var currency = params.get('currency');
var balance = parseFloat(params.get('balance'));

API.token = params.get('token');

globals.currency = currency ? currency : 'USD';
globals.demoBalance = params.get('demo_balance');
if(globals.demoBalance) globals.balance = 1100;
else globals.balance = balance ? balance : 0;

Vue.prototype.$g = new Vue({data: globals});	//global storage and event bus
Vue.prototype.$api = API;
Vue.mixin(notifications);

new Vue({
	i18n: new VueI18n({
		locale: locale ? locale : 'ru',
		messages: translations
	}),
	render: function (h) { return h(App) }
}).$mount('#app')
