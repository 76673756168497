//part of the app component which is responsible for handling information messages
export const NTFCN_SUCCESS = 'success';
export const NTFCN_WARNING = 'warning';
export const NTFCN_DANGER = 'danger';
export const NTFCN_INFO = 'info';

export default {
	methods: {
		addMessage(message) {
			message.timeout = setTimeout(() => this.removeMessage(message), message.timeout ? message.timeout : 5000);
			this.$g.messages.push(message);
		},
		removeMessage(message){
			if(message.timeout) clearTimeout(message.timeout);
			let i = this.$g.messages.indexOf(message);
			if(i == -1) return;
			this.$g.messages.splice(i, 1);
		},
		//shortcuts for basic often used messages
		infoMessage(text) {
			this.addMessage({ style: NTFCN_INFO, text: text });
		},
		warningMessage(text){
			this.addMessage({ style: NTFCN_WARNING, text: text });
		},
		errorMessage(text){
			this.addMessage({ style: NTFCN_DANGER, text: text });
		},
		successMessage(text){
			this.addMessage({ style: NTFCN_SUCCESS, text: text });
		},
	}
}