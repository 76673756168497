import axios from 'axios';
import { io } from 'socket.io-client';
import globals from './global.js';

const http = axios.create({baseURL: 'https://concorde-iframe.com:3000'});

export default {
	states: {
		start: 'Betting',
		flight: 'Flight',
		end: 'Finished'
	},
	token: null,
	socket: null,
	requests: {	//cache some request promises which need to be accessed from multiple places
		game: null,
		bets: null
	},
	getGame(reset){
		if(!this.token) return;
		if(reset) this.requests.game = null;
		if(this.requests.game) return this.requests.game;
		return this.requests.game = http.get('/game', {headers: {'x-access-token': this.token}}).then(response => {
			globals.game = response.data[0];
			return response;
		});
	},
	getJackpot(){
		if(!this.token) return;
		return http.get('/jackpot', {headers: {'x-access-token': this.token}, params: {val: globals.currency}});
	},
	getBalance(){
		return http.get('/users/balance', {headers: {'x-access-token': this.token}});
	},
	getHistory(){
		if(!this.token) return;
		return http.get('/game/all', {headers: {'x-access-token': this.token, perpage: 50, orderby: 'id', orderdir: 'DESC'}});
	},
	getOwnBets(reset){
		if(!this.token) return;
		if(reset) this.requests.bets = null;
		if(this.requests.bets) return this.requests.bets;
		return this.requests.bets = http.get('/bet/user', {headers: {'x-access-token': this.token}, params: {val: globals.currency}});
	},
	getGameBets(){
		if(!this.token) return;
		return http.get('/bet/game', {headers: {'x-access-token': this.token}, params: {val: globals.currency}});
	},
	getBetStats(){
		if(!this.token) return;
		return http.get('/bet/24hours', {headers: {'x-access-token': this.token}, params: {val: globals.currency}});
	},
	bet(amount){
		return http.post('/bet/', {
			Amount: amount,
			Bet: 2,
			Date: Date.now(),
			Tirazh: globals.game.id,
			Valute: globals.currency
		}, {headers: {'x-access-token': this.token}})
	},
	getBet(id){
		return http.get('/bet/'+id, {headers: {'x-access-token': this.token}});
	},
	connectSocket(){
		this.socket = io('https://concorde-iframe.com:3001', {
			extraHeaders: {
				Authorization: this.token
			}
		});
	}
}