export default [
	'abstract-science-12214.mp3',
	'air-15286.mp3',
	'amber-sky-10946.mp3',
	'ambient-light-main-7229.mp3',
	'awakening-instrumental-1165.mp3',
	'basement-21883.mp3',
	'be-on-my-way-109191.mp3',
	'boom-bap-groove-18306.mp3',
	'both-of-us-14037.mp3',
	'cali-1171.mp3',
	'calming-in-the-sun-11173.mp3',
	'carefree-time-109064.mp3',
	'chill-abstract-intention-12099.mp3',
	'come-on-16497.mp3',
	'cyber-punk-21548.mp3',
	'desitalk-exclusive-life-is-strange-early-flute-and-guitar-mix-20834.mp3',
	'elegant-ambient-science-12560.mp3',
	'every-time-we-say-goodbye-13515.mp3',
	'fonovaja_muzika_dlja_video_-_my_lucky_stars_(z2.fm).mp3',
	'for-food-99185.mp3',
	'freshness-15706.mp3',
	'goin-crazie-21885.mp3',
	'hip-hop-beat-5784.mp3',
	'inspiring-science-technology-12215.mp3',
	'into-the-night-20928.mp3',
	'journey-starts-from-one-step-15614.mp3',
	'light-positive-disco-18313.mp3',
	'madirfan-unreleased-demo-10-04-2022-109270.mp3',
	'modern-summer-10534.mp3',
	'my-lonely-journey-11299.mp3',
	'mystery-109163.mp3',
	'on-the-edge-10938.mp3',
	'penguinmusic-bright-energetic-electronica-12635.mp3',
	'rap-news-95447.mp3',
	'regret-21696.mp3',
	'romantic-hip-hop-groove-18303.mp3',
	'space-22052.mp3',
	'space-age-10714.mp3',
	'thats-my-nigga-51466.mp3',
	'the-boom-bap-vibe-22164.mp3',
	'the-future-bass-15017.mp3',
	'the-magic-pearl-chill-out-music-11917.mp3',
	'thinking-forward-108884.mp3',
	'third-lane-21968.mp3',
	'trailer-sport-action-16203.mp3',
	'trap-anthem-21233.mp3',
	'travel-to-the-city-8752.mp3',
	'veselaja_fonovaja_muzika_-_fon_(z2.fm).mp3',
	'vlog-groovy-hip-hop-18304.mp3',
	'watr-fluid-10149.mp3',
	'welcome-to-the-games-15238.mp3',
	'zodiac-21752.mp3'
]