<template>
	<div class="game">
		<div class="sizing" ref="sizing">
			<div class="container" :class="{active: !$g.loading}">
				<video playsinline muted ref="flyoff" :src="src.flyoff"
					class="canvas" :class="{active: animation && animation == $refs.flyoff}"></video>
				<video playsinline muted ref="flying" :src="src.flying"
					class="canvas" :class="{active: animation && animation == $refs.flying}" loop="1"></video>
				<video playsinline muted ref="destroyed" :src="src.destroyed"
					class="canvas" :class="{active: animation && animation == $refs.destroyed}"></video>
				<div v-if="$g.coefficient" class="coefficient" :class="{over: $g.game.State != $api.states.flight}">{{$g.coefficient.toFixed(2)}}</div>
				<div v-if="betting" class="bet-time">
					<div v-if="bettingProgress" class="bet-progress" :style="{width: bettingProgress+'%'}"></div>
					{{$t('betting_time')}}
				</div>
			</div>
			<div class="notifications">
				<div v-for="message in $g.messages" class="notice" :class="[message.style]">
					<div v-if="message.text">{{message.text}}</div>
					<div v-if="!message.unclosable" class="close" @@click="removeMessage(message)"></div>
				</div>
			</div>
		</div>
		<betting :index="0" />
		<betting v-if="!$g.isTabletSize" :index="1"/>
		<div v-if="error" class="error-screen">{{error}}</div>
	</div>
</template>

<script>
import betting from '@/components/Betting.vue'

const COEF_START = 1;
const COEF_PER_SEC = 0.5;

const BETTING_DURATION = 9000;	//duration of betting period (before flyoff) in milliseconds

export default {
	name: 'Game',
	components: { betting },
	data(){
		return {
			animation: null,
			error: null,
			flightStart: null,
			bettingInterval: 0,
			bettingProgress: 0,
			src: {
				flyoff: null,
				flying: null,
				destroyed: null
			}
		}
	},
	computed: {
		betting(){
			if(!this.$g.game) return false;
			return this.$g.game.State == this.$api.states.start;
		},
		/*containerClass(){
			return [{active: !this.$g.loading}, this.$g.game ? this.$g.game.State.toLowerCase() : ''];
		}*/
	},
	created(){
		window.game = this;
		this.$g.loading = true;
		this.src.flyoff = this.$g.isMobileSize ? 'video/flyoff-mobile.mp4' : 'video/flyoff.mp4';
		this.src.flying = this.$g.isMobileSize ? 'video/flying-mobile.mp4' : 'video/flying.mp4';
		this.src.destroyed = this.$g.isMobileSize ? 'video/destroyed-mobile.mp4' : 'video/destroyed.mp4';
	},
	mounted(){
		this.setSize();
		window.addEventListener('resize', this.setSize);
		
		let loaded = 0;
		let flyoffCanPlay = () => {
			this.$refs.flyoff.removeEventListener('canplaythrough', flyoffCanPlay);
			loaded++;
			if(loaded >= 3) this.initGame();
		}
		let flyingCanPlay = () => {
			this.$refs.flying.removeEventListener('canplaythrough', flyingCanPlay);
			loaded++;
			if(loaded >= 3) this.initGame();
		}
		let destroyedCanPlay = () => {
			this.$refs.destroyed.removeEventListener('canplaythrough', destroyedCanPlay);
			loaded++;
			if(loaded >= 3) this.initGame();
		}
		this.$refs.flyoff.addEventListener('canplaythrough', flyoffCanPlay);
		this.$refs.flying.addEventListener('canplaythrough', flyingCanPlay);
		this.$refs.destroyed.addEventListener('canplaythrough', destroyedCanPlay);
		this.$refs.flyoff.load();
		this.$refs.flying.load();
		this.$refs.destroyed.load();
		this.$refs.flyoff.addEventListener('timeupdate', () => {
			if(this.$refs.flyoff.currentTime <= (this.$refs.flyoff.duration - 0.5)) return;
			this.$refs.flyoff.pause();
			this.$refs.flying.currentTime = 0;
			this.animation = this.$refs.flying;
			this.animation.play();
		});
		this.$refs.destroyed.addEventListener('ended', () => {
			this.reset();
		});
	},
	methods: {
		initGame(){
			this.$g.loading = true;
			this.$api.getGame().then(response => {
				//this.error = null;
				this.$g.loading = false;
				this.setState(this.$g.game);
				if(this.$g.game.State === this.$api.states.flight && this.$g.game.Date){
					this.flightStart = this.$g.game.Date + BETTING_DURATION;
				}
				if(!this.bettingInterval) this.bettingInterval = setInterval(this.updateBetting, 100);
				this.$api.socket.on('game_state', this.setState);
				this.$api.socket.on('coefficient', coefficient => {
					if(this.$g.game.State === this.$api.states.flight) this.$g.coefficient = coefficient;
				}),
				this.$api.socket.on('flyoff', () => {
					if(this.animation != this.$refs.flyoff) this.animation = this.$refs.flyoff;
					this.$refs.flyoff.currentTime = 0;
					this.$refs.flyoff.play();
				});
				return response;
			}).catch(error => {
				//this.error = 'could not connect to server';
				setTimeout(() => this.initGame(), 5000);
			});
		},
		resetGame(){
			this.$g.loading = true;
			this.$api.socket.emit('get_state');
			this.$api.getGame(true).then(response => {
				this.$g.loading = false;
				this.setState(this.$g.game);
				if(this.$g.game.State === this.$api.states.flight && this.$g.game.Date){
					this.flightStart = this.$g.game.Date + BETTING_DURATION;
				}
				if(!this.bettingInterval) this.bettingInterval = setInterval(this.updateBetting, 100);
				return response;
			}).catch(error => {
				setTimeout(() => this.initGame(), 5000);
			});
		},
		setSize(){
			if(this.$g.isTabletSize || this.$g.isMobileSize){
				this.$refs.sizing.style.width = '';
				this.$refs.sizing.style.height = '';
				return;
			}
			const HISTORY_WIDTH = 82;
			const BETS_WIDTH = 293;
			const BETTING_WIDGET_HEIGTH = 125;
			const RATIO = 1.8;
			let bbox = this.$el.getBoundingClientRect();
			let gameHeight = bbox.height - BETTING_WIDGET_HEIGTH;
			let gameWidth = gameHeight * RATIO;
			let maxWidth = window.innerWidth - HISTORY_WIDTH - BETS_WIDTH;
			if(gameWidth > maxWidth){
				gameWidth = maxWidth;
				gameHeight = maxWidth / RATIO;
			}
			this.$refs.sizing.style.width = gameWidth + 'px';
			this.$refs.sizing.style.height = gameHeight + 'px';
		},
		setState(data){
			this.$g.loading = false;
			data.Date = data.Date ? parseInt(data.Date) : Date.now();
			this.$g.game.Date = data.Date;
			switch(data.State){
				case this.$api.states.start:
					this.$g.game.id = data.id;
					if(this.animation) break;
					this.reset();
				break;
				case this.$api.states.flight:
					if(this.animation) this.animation.pause();
					this.animation = this.$refs.flying;
					this.$refs.flying.play();
					setTimeout(() => this.$refs.flyoff.currentTime = 0, 1000);
					this.flightStart = Date.now();
				break;
				case this.$api.states.end:
					if(this.animation) this.animation.pause();
					this.animation = this.$refs.destroyed;
					this.$refs.destroyed.play();
					if(this.$g.audio.explosion) this.$g.audio.explosion.play();
					this.flightStart = null;
					//make sure that correct coefficient is displayed
					this.$g.coefficient = parseFloat(data.result);
				break;
			}
			this.$g.game.State = data.State;
		},
		reset(){
			this.$g.coefficient = 0;
			this.animation = this.$refs.flyoff;
			let animationDuration = game.$refs.flyoff.duration * 1000;
			let estimatedStart = this.$g.game.Date + BETTING_DURATION;
			let timeRemaining = estimatedStart - Date.now();
			//console.log(new Date(this.$g.game.Date), new Date(estimatedStart));
			if(timeRemaining < animationDuration){
				let timelinePosition = game.$refs.flyoff.duration - timeRemaining / 1000;
				//console.log(timeRemaining, animationDuration, timelinePosition, game.$refs.flyoff.duration);
				this.animation.currentTime = timelinePosition;
				//console.log(this.animation, 'flyoff play');
				this.animation.play();
				return;
			}
		},
		updateBetting(){
			if(!this.$g.game) return this.bettingProgress = 0;
			if(this.$g.game.State != this.$api.states.start) return this.bettingProgress = 0;
			let estimatedStart = this.$g.game.Date + BETTING_DURATION;
			let timeRemaining = estimatedStart - Date.now();
			this.bettingProgress = 100 - Math.floor(timeRemaining / BETTING_DURATION * 100);
		}
	}
}
</script>
<style>
	.game{
		position: relative;
		z-index: 1;
		max-width: calc(100% - 375px);
		max-height: 100%;
		margin-right: 1px;
	}
	.sizing{
		position: relative;
		display: flex;
		justify-content: center;
		max-width: 100%;
		min-width: 730px;
		height: calc(100% - 125px);
	}
	.container{
		position: relative;
		width: 100%;
		min-width: 730px;
		visibility: hidden;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
	.container.active{
		visibility: visible;
		background-image: url('~@/assets/flying.jpg');
	}
	.canvas{
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		visibility: hidden;
		pointer-events: none;
	}
	.canvas.active{
		z-index: 5;
		visibility: visible;
	}
	.overlay{
		pointer-events:none;
		overflow:hidden;
		width:550px;
		height:400px;
		position: absolute;
		left: 0px;
		top: 0px;
		display: block;
	}
	.coefficient{
		position: absolute;
		left: 0;
		bottom: 50%;
		right: 0;
		z-index: 50;
		display: flex;
		padding-bottom: 18%;
		justify-content: center;
		font-size: 50px;
		font-weight: bold;
		color: green;
	}
	.coefficient.over{
		color: red;	
	}
	.bet-time{
		position: absolute;
		bottom: 0;
		left: 50%;
		z-index: 50;
		transform: translateX(-50%);
		padding: 0 10px;
		border: 1px solid #000;
		border-radius: 5px;
		box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
		background: radial-gradient(54.85% 43.44% at 50% 42.78%, #f1f5ff 0%, #868b97 100%);
		color: #1d2c34;
		line-height: 22px;
		font-size: 20px;
		text-transform: uppercase;
		white-space: nowrap;
	}
	.bet-progress{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		max-width: 100%;
		background: rgba(0, 223, 9, 0.5);
	}

	.notifications{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 150;
		display:flex;
		flex-direction:column-reverse;
		align-items:center;
		pointer-events: none;
	}
	.notice{
		position: relative;
		display: block;
		width: 290px;
		margin-top: 5px;
		padding: 5px 10px;
		border-radius: 5px;
		box-shadow: inset 0px 0px 6px 4px rgba(0, 0, 0, 0.25);
		background: #4c84ff;
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		transition: opacity .5s;
		pointer-events: all;
	}
	.notice.success{
		background: #00df09;
	}
	.notice.warning{
		background: #fff500;
	}
	.notice.danger{
		background: #ff5c38;
	}

	@media (max-width: 1100px) {
		.sizing, .container, .canvas {
			min-width: 0;
		}
	}

	@media (max-width: 1000px) {
		.game{
			flex: 0;
			max-width: none;
		}
		.game{
			height: auto;
		}
		.sizing{
			position: relative;
			max-width: 600px;
			height: auto;
			margin: 0 auto;
		}
		.sizing:before{
			content: '';
			display: block;
			padding-top: 57%;
		}
		.coefficient{
			top: 50px;
			bottom: auto;
			padding-bottom: 0;
		}
		.notice{
			padding: 3px;
			line-height: 14px;
			font-size: 14px;
		}
	}
	@media (max-width: 600px) {
		.bet-time{
			line-height: 4.2vw;
			font-size: 4vw;
		}
	}
</style>