<template>
	<div class="bets">
		<div class="tabs">
			<div :class="{active: activeTab === tabs.game}" @click="activeTab = tabs.game">{{$t('tab_game_bets')}}</div>
			<div :class="{active: activeTab === tabs.own}" @click="activeTab = tabs.own">{{$t('tab_my_bets')}}</div>
			<div :class="{active: activeTab === tabs.statistic}" @click="activeTab = tabs.statistic" class="stats">{{$t('tab_statictics')}}</div>
			<div :class="{active: activeTab === tabs.statistic}" @click="activeTab = tabs.statistic" class="history">{{$t('tab_history')}}</div>
		</div>
		<table class="tab" :class="{active: activeTab === tabs.game}">
			<tr>
				<th>{{$t('user')}}</th>
				<th>{{$t('bet')}}</th>
				<th>{{$t('coefficient')}}</th>
				<th>{{$t('gain')}}</th>
			</tr>
			<tr v-for="bet in game" :class="{won: bet.Result}">
				<td>{{bet.User | obfuscate}}</td>
				<td>{{bet.Amount}}</td>
				<td>{{bet.Result === 'Win' ? bet.Bet : 0}}</td>
				<td>{{bet.Result === 'Win' ? (bet.Amount * bet.Bet).toFixed(2) : bet.Result}}</td>
			</tr>
		</table>
		<table class="tab" :class="{active: activeTab === tabs.own}">
			<tr>
				<th>{{$t('time')}}</th>
				<th>{{$t('bet')}}</th>
				<th>{{$t('coefficient')}}</th>
				<th>{{$t('gain')}}</th>
			</tr>
			<tr v-for="bet in $g.ownBets" :class="{won: bet.Result == 'Win', lost: bet.Result == 'Lose'}">
				<td>{{formatDate(bet.Date)}}</td>
				<td>{{bet.Amount}}</td>
				<td>{{bet.Result === 'Win' ? bet.Bet : 0}}</td>
				<td>{{bet.Result === 'Win' ? (bet.Amount * bet.Bet).toFixed(2) : bet.Result}}</td>
			</tr>
		</table>
		<table class="tab stats" :class="{active: activeTab === tabs.statistic}">
			<tr>
				<th>{{$t('user')}}</th>
				<th>{{$t('bet')}}</th>
				<th>{{$t('coefficient')}}</th>
				<th>{{$t('gain')}}</th>
			</tr>
			<tr v-for="bet in statistic" :class="{won: bet.Result == 'Win', lost: bet.Result == 'Lose'}">
				<td>{{bet.User | obfuscate}}</td>
				<td>{{bet.Amount}}</td>
				<td>{{bet.Result === 'Win' ? bet.Bet : 0}}</td>
				<td>{{bet.Result === 'Win' ? (bet.Amount * bet.Bet).toFixed(2) : bet.Result}}</td>
			</tr>
		</table>
		<table class="tab history" :class="{active: activeTab === tabs.statistic}">
			<tr v-for="item in $g.history">
				<td>{{item.Result}}</td>
			</tr>
		</table>
	</div>
</template>

<script>
import notifications from './notifications.js';

const tabs = {own: 1, game: 2, statistic: 3};

export default {
	name: 'Bets',
	mixins: [notifications],
	data(){
		return {
			activeTab: tabs.game,
			game: [],
			statistic: []
		};
	},
	filters: {
		obfuscate(username){
			if(typeof username != 'string' || username.length < 3) return username;
			return username.slice(0,1) + '*'.repeat(username.length - 2) + username.slice(username.length - 1);
		}
	},
	created(){
		this.tabs = tabs;
		this.getOwnBets();
		this.getStats();
		this.getGameBets();
		this.$api.socket.on('Bet Placed', data => {
			if(!data.bet) return;
			data.bet.Result = null;
			if(data.User) data.bet.User = data.User;
			if(data.id) data.bet.id = data.id;
			this.game.push(data.bet);
			this.game.sort((a, b) => b.Amount - a.Amount);
			if(data.jackpot && data.jackpot > this.$g.jackpot) this.$g.jackpot = data.jackpot;
		});
		this.$api.socket.on('game_state', state => {
			if(state.State != this.$api.states.end) return;
			this.getOwnBets(true);
			this.getStats();
			this.game = [];
		});
		this.$api.socket.on('Jumped', data => {
			this.game.forEach(bet => {
				if(bet.id != data.id) return;
				bet.Result = 'Win';
				bet.Bet = data.Bet;
			});
		});
	},
	methods: {
		getGameBets(){
			this.$api.getGameBets().then(response => {
				this.game = response.data.sort((a, b) => a.Amount - b.Amount);
			});
		},
		getOwnBets(reset){
			this.$api.getOwnBets(reset).then(response => {
				this.$g.ownBets = response.data.slice(0, 50);
				return response;
			});
		},
		getStats(){
			this.$api.getBetStats().then(response => {
				this.statistic = response.data;
			});
		},
		formatDate(rawDate){
			let timestamp = parseInt(rawDate);
			if(!timestamp) return '';
			let date = new Date(timestamp);
			if(!date) return '';
			return date.toLocaleString();
		}
	}
}
</script>

<style>
	.bets{
		flex: 1;
		width: 293px;
		height: 100%;
		overflow: auto;
		line-height: 16px;
		text-align: center;
	}
	.tabs{
		display: flex;
		align-items: center;
		padding-top: 2px;
		background: #5a6f8d;
		font-weight: 500;
		font-size: 14px;
	}
	.tabs div{
		flex: 1;
		width: 33%;
		height: auto;
		padding: 10px;
		cursor: pointer;
	}
	.tabs .active{
		color: #ff000f;
	}
	.tabs .history,
	.tab.active.history{
		display: none;
	}
	.tab{
		display: none;
		margin: 0 2px 0 4px;
		border-spacing: 0 1px;
		width: calc(100% - 6px);
	}
	.tab.active{
		display: table;
	}
	.tab th{
		height: 32px;
		padding-top: 4px;
		color: #80a1d0;
		font-size: 9px;
		font-weight: 500;
		text-transform: uppercase;
	}
	.tab td{
		height: 23px;
		background: #071623;
		color: #b8c5d8;
		line-height: 14px;
		font-size: 12px;
	}
	.tab.history td{
		color: #fff500;
	}
	.tab tr td:first-child{
		border-radius: 5px 0 0 5px;
	}
	.tab tr td:last-child{
		border-radius: 0 5px 5px 0;
	}
	.bets .lost td{
		color: #f00;
	}
	.bets .won td{
		color: #0f0;
	}

	@media (max-width: 1000px) {
		.bets{
			width: 100%;
			height: 33%;
		}
		.stats,
		.stats.active{
			display: none;
		}
		.tabs .history{
			display: block;
		}
		.tab.active.history{
			display: table;
		}
	}

	@media (max-width: 680px) {
		.bets{
			flex: 1;
			height: auto;
		}
	}
</style>