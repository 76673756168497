export default {
	lang: 'en',
	currency: 'USD',
	balance: 0,
	demoBalance: false,	//indication that frame should use temporary front-end balance instead of back-end balance
	messages: [],
	history: [],
	ownBets: [],
	game: null,
	jackpot: 0,
	coefficient: 0,
	isMobileSize: false,
	isTabletSize: false,
	loading: false,
	lastActiveTime: null,
	muted: true,
	soundMuted: true,
	audio: {
		music: null,
		explosion: null,
		bet: null,
		withdraw: null
	}
}