<template>
	<div class="history">
		<div v-for="item in $g.history">{{item.Result}}</div>
	</div>
</template>

<script>
export default {
	name: 'History',
	created(){
		this.$api.getHistory().then(response => {
			this.$g.history = response.data.slice(1);
			this.$api.socket.on('game_state', data => {
				if(data.State != this.$api.states.end) return;
				let result = parseFloat(parseFloat(data.result).toFixed(2));	//force rounding
				this.$g.history.unshift({Result: result});
			})
		});
	}
}
</script>

<style>
	.history{
		flex-shrink: 0;
		width: 82px;
		height: 100%;
		padding: 0 2px;
		overflow: auto;
		line-height: 20px;
		text-align: center;
	}
	.history div{
		height: 25px;
		margin-bottom: 2px;
		border: 1px solid #4865ca;
		border-radius: 5px;
		background: #071623;
		color: #fff500;
		line-height: 25px;
		font-size: 14px;
		font-weight: 500;
	}

	@media (max-width: 1000px) {
		.history{
			display: none;
		}
	}
</style>