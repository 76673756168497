export default {
	en: {
		bet: 'bet',
		take: 'take',
		make_bet: 'Make bet',
		take_bet: 'Withdraw',
		registering: 'Registering...',
		bet_register_err: 'Bet already being registered',
		bet_min_err: 'Minimum bet amount is {0}',
		bet_max_err: 'Maximum bet amount is {0}',
		take_min_err: 'Minimum withdrawal ratio is {0}',
		err_balance: 'You do not have enough funds on balance',
		err_server_balance: 'Your bet has been rejected, you do not have enough funds on balance',
		err_server_min: 'Your bet has been rejected, please enter higher sum',
		err_server_min_sum: 'Your bet has been rejected, please enter sum higher than {0}',
		err_server_max: 'Your bet has been rejected, please enter lower sum',
		err_server_max_sum: 'Your bet has been rejected, please enter sum lower than {0}',
		err_invalid_currency: 'Your currency is invalid, please contact support',
		cancel: 'cancel',
		ok: 'ok',
		balance: 'balance',
		next_round: 'on next round',
		this_round: 'on this round',
		bet_accepted: 'Your bet has been accepted',
		bet_rejected: 'Your bet has been rejected',
		auto_bet_accepted: 'Automatically registered bet',
		auto_bet_rejected: 'Automatic bet registration failed',
		jump_error: 'Withdrawal failed for following reason: {0}',
		rules: 'Rules',
		how_to_play: 'How to play',
		info_step_1: 'Make a bet before flyoff',
		info_step_2: 'Risk and wait for coefficient to increase',
		info_step_3: 'Make cashout before explosion',
		rules_title: 'concord is a new game where you can win money quickly and easily.',
		rules_subtitle1: 'how to play concord? ',
		rules_text1: 'enter - the desired amount in the bet line, you have the ability to make one or two bets in one round. the minimum bet is 0.1 and the maximum is 300.',
		rules_subtitle2: 'how is the payoff calculated?',
		rules_text2: 'the win is calculated by multiplying the current height coefficient by the amount of the bet.',
		rules_subtitle3: 'how do i get the win',
		rules_text3: 'the player wins if he manages to use the pick button before the explosion (the profit is instantly displayed on the balance sheet). in the event of an explosion, the bet is considered lost.',
		rules_subtitle4: 'how is the jackpot played?',
		rules_text4: '0.03% goes to the jackpot from each bet, that is, if the bet is $ 100, then $ 3 goes to the jackpot, when the jackpot falls out - this is done at full randomness.',
		rules_disclaimer: 'the site administration reserves the right to amend the rules and conditions of the game, as well as completely stop it.',
		tab_game_bets: 'Current Bets',
		tab_my_bets: 'My bets',
		tab_statictics: '24 Hours Statistic',
		tab_history: 'History',
		user: 'user',
		coefficient: 'coefficient',
		gain: 'gain',
		time: 'time',
		betting_time: 'Betting time',
		bet_taken: 'You have taken bet on coefficient {0} for a total of {1}',
		enable_music: 'Enable music?',
		enable_audio: 'Your device requires permission to play sound effects, enable?',
		reset_session: 'Your tab was inactive for some time, which may impact game performance, do you want to refresh game?',
		yes: 'yes',
		no: 'no'
	},
	ru: {
		bet: 'ставка',
		take: 'забрать',
		make_bet: 'Cделайте ставку',
		take_bet: 'Забрать',
		registering: 'Регистрация...',
		bet_register_err: 'Ставка уже регистрируется',
		bet_min_err: 'Минимальная сумма ставки {0}',
		bet_max_err: 'Максимальная сумма ставки {0}',
		take_min_err: 'Минимальный коэффициент {0}',
		err_balance: 'У вас недостаточно средств на балансе',
		err_server_balance: 'Ваша ставка отклонена, у вас недостаточно средств на балансе',
		err_server_min: 'Ваша ставка отклонена, введите большую сумму',
		err_server_min_sum: 'Ваша ставка отклонена, введите сумму больше {0}',
		err_server_max: 'Ваша ставка отклонена, введите меньшую сумму',
		err_server_max_sum: 'Ваша ставка отклонена, введите сумму меньше {0}',
		err_invalid_currency: 'Ваша валюта недействительна, обратитесь в службу поддержки',
		cancel: 'отмена',
		ok: 'ок',
		balance: 'баланс',
		next_round: 'на следующий раунд',
		this_round: 'на текущий раунд',
		bet_accepted: 'Ваша ставка принята',
		bet_rejected: 'Ваша ставка отклонена',
		auto_bet_accepted: 'Ставка автоматически зарегистрирована',
		auto_bet_rejected: 'Не удалось автоматически зарегистрировать ставку',
		jump_error: 'Вывод не сработал, ошибка: {0}',
		rules: 'Правила',
		how_to_play: 'Как играть',
		info_step_1: 'Сделай ставку до взлета',
		info_step_2: 'Рискни и дождись роста коэффициента',
		info_step_3: 'Сделай кешаут до взрыва',
		rules_title: 'конкорд - это новая игра , в которой можешь быстро и легко выиграть деньги.',
		rules_subtitle1: 'как играть в конкорд?',
		rules_text1: 'введи - желаемую сумму в строке ставки у вас есть способность сделать одну или две ставки в одном раунде. минимальная ставка составляет 0.1, а максимальная 300.',
		rules_subtitle2: 'как вычисляется выигрыш?',
		rules_text2: 'выигрыш вычисляется умножением текущего показателя высоты на сумму ставки.',
		rules_subtitle3: 'как я получаю выигрыш',
		rules_text3: 'игрок выигрывает, если успеет использовать кнопку забрать до взрыва ( прибыль мгновенно отображается на балансе ). в случае взрыва ставки считается потерянной.',
		rules_subtitle4: 'как разыгрывается джек пот?',
		rules_text4: 'в джекпот с каждой ставки идет 0.03%, то есть если ставка 100$,то в джекпот идет 3$, когда выпадет джекпот - это сделано на полном рандоме.',
		rules_disclaimer: 'администрация сайта оставляет за собой права внести изменения в правила и условия игры, а также полностью остановить ее.',
		tab_game_bets: 'Текущие ставки',
		tab_my_bets: 'Мои ставки',
		tab_statictics: 'Статистика 24 часа',
		tab_history: 'История',
		user: 'пользователь',
		coefficient: 'коэффициент',
		gain: 'выигрыш',
		time: 'время',
		betting_time: 'Время приема ставок',
		bet_taken: 'Вы забрали ставку с коэффициентом {0} на сумму {1}',
		enable_music: 'Включить звук?',
		enable_audio: 'Ваше устройство требует разрешения для звуковых эффектов, разрешить?',
		reset_session: 'Ваша вкладка некоторое время была неактивна, что может повлиять на производительность игры. Обновить игру? ',
		yes: 'да',
		no: 'нет'
	}
}